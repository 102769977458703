import { useAuth } from 'src/providers/AuthProvider'
import { useUser } from 'src/providers/UserProvider'

import { Divider } from 'src/components/atoms/Divider'
import {
  ExternalLink,
  InternalLink,
  OnClickLink
} from 'src/components/atoms/Link'
import { Switch } from 'src/components/atoms/Switch'
import { AccountInfo } from 'src/components/molecules/AccountInfo'
import { UserInfo } from 'src/components/molecules/UserInfo'

import { useListRegistriesQuery } from 'src/config/generated/graphql'
import {
  listFundingPath,
  listMyRegistriesPath,
  taxCertificatesPath,
  viewAccountDetailPath,
  viewAccountUsersPath
} from 'src/config/paths'
import { ActiveProfileType } from 'src/types/account'

export const AccountMenu = () => {
  const { isStaff, isAdvisor, logout, login, signup } = useAuth()
  const { isAdminMode, setAdminMode, user } = useUser()
  const { data: registriesData } = useListRegistriesQuery({
    variables: {
      funderId: user?.Account?.FunderId,
      offset: 0,
      limit: 1
    }
  })
  const handleChangeAdminToggle = () => {
    setAdminMode(!isAdminMode)
  }

  return (
    <div className='min-w-[340px] max-w-[340px] z-0 rounded-[10px] overflow-clip bg-white shadow-[0px_4px_8px_2px_rgba(51,51,51,0.15)]'>
      {/* admin mode switch */}
      <div className='bg-anotherGrey4'>
        {isStaff || isAdvisor ? (
          <>
            <div className='space-x-4 p-5 text-shark text-[16px] font-medium'>
              <span>{isAdminMode ? 'Admin' : 'App'}</span>
              <Switch
                title='Admin'
                checked={isAdminMode}
                onChange={handleChangeAdminToggle}
              />
            </div>
            <Divider className='!m-0' />
          </>
        ) : null}
        <div className='bg-anotherGrey4 p-5'>
          <UserInfo />
        </div>
      </div>
      {user?.Account ? (
        <div className='p-5'>
          <AccountInfo />
        </div>
      ) : null}
      <Divider className='!m-0' />
      {user ? (
        <>
          {user.Account &&
          user.ActiveProfileType === ActiveProfileType.Funder ? (
            <>
              <InternalLink
                className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
                color='black'
                highlightActive
                href={listFundingPath}
              >
                Transactions
              </InternalLink>
              <InternalLink
                className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
                color='black'
                highlightActive
                href={taxCertificatesPath}
              >
                Tax certificates
              </InternalLink>
              {registriesData?.registries.length ? (
                <InternalLink
                  className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
                  color='black'
                  highlightActive
                  href={listMyRegistriesPath}
                >
                  Your tree registries
                </InternalLink>
              ) : null}
            </>
          ) : null}
          {user.Account ? (
            <InternalLink
              className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
              color='black'
              highlightActive
              href={viewAccountUsersPath}
            >
              Manage users
            </InternalLink>
          ) : null}
          <InternalLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            highlightActive
            href={viewAccountDetailPath}
          >
            Account settings
          </InternalLink>
          <Divider className='!m-0' />
          <ExternalLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            href='https://help.treesthatcount.co.nz/en/'
          >
            Help centre
          </ExternalLink>
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={logout}
          >
            Log out
          </OnClickLink>
        </>
      ) : (
        <>
          <OnClickLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            onClick={signup}
          >
            Sign up
          </OnClickLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={login}
          >
            Log in
          </OnClickLink>
        </>
      )}
    </div>
  )
}
