import { useAuth } from 'src/providers/AuthProvider'
import { useUser } from 'src/providers/UserProvider'

import { Image } from 'src/components/atoms/Image'
import {
  ButtonLink,
  InternalLink,
  OnClickLink
} from 'src/components/atoms/Link'
import { MenuDropdown } from 'src/components/molecules/MenuDropdown'
import { AccountDropdown } from 'src/components/organisms/AccountDropdown'

import { dashboardPath, donatePath, giftPath, homePath } from 'src/config/paths'
import { ActiveProfileType } from 'src/types/account'

import { LinkOption } from '../types'

export interface DesktopNavigationProps {
  links: LinkOption[]
}

export const DesktopNavigation = ({ links }: DesktopNavigationProps) => {
  const { user, memberships, isAdminMode } = useUser()
  const { loginWithCustomRedirect, signup } = useAuth()

  const renderLink = (menuItem: LinkOption) => {
    const { requiresUser, options, label, path } = menuItem

    if (!requiresUser || (requiresUser && user)) {
      if (options && options.length) {
        return <MenuDropdown key={label} menuItem={menuItem} />
      }
      if (!path) return null
      return (
        <div key={label} className='text-[16px] font-medium p-[10px]'>
          <InternalLink color='black' href={path as string} highlightActive>
            <span className='truncate max-w-[200px]' title={label}>
              {label}
            </span>
          </InternalLink>
        </div>
      )
    }
  }

  const renderAccountLinks = () => {
    if (memberships && user) {
      return (
        <div key='accounts' className='mx-2 shrink-0'>
          <AccountDropdown />
        </div>
      )
    }
  }

  return (
    <div className='xl:mx-[110px] lg:mx-[30px] mx-[20px] w-full max-w-[1220px] flex-row items-center hidden lg:relative lg:visible lg:flex lg:flex-[inherit]'>
      <InternalLink
        href={homePath}
        className={`relative w-10 h-10 lg:h-[60px] ${
          user ? 'lg:w-[60px]' : 'w-40'
        }`}
      >
        <Image
          fill
          alt='Trees That Count logo'
          src={user ? '/img/brand-mobile.svg' : '/img/logos/ttc-logo.svg'}
          className='object-contain'
        />
      </InternalLink>
      <div className='flex items-center w-full ml-[20px]'>
        {/* admin/user links */}
        <div className='flex flex-col sm:flex-row items-center w-full pl-0 list-none'>
          <div className='flex justify-between items-center w-full'>
            <div className='flex gap-[5px]'>
              {links.map(linkOptions => renderLink(linkOptions))}
            </div>

            <div className='flex justify-between items-center'>
              {/* signup/login links */}
              {!user && (
                <div className='flex items-center mx-8'>
                  <div className='mx-2'>
                    <OnClickLink color='black' onClick={signup}>
                      Sign up
                    </OnClickLink>
                  </div>

                  <div className='mx-2'>
                    <OnClickLink
                      color='black'
                      onClick={() => {
                        loginWithCustomRedirect(dashboardPath())
                      }}
                    >
                      Login
                    </OnClickLink>
                  </div>
                </div>
              )}

              {/* gift/donate buttons */}
              <div className='flex items-center space-x-2 mr-1'>
                {!user ? (
                  <div>
                    <ButtonLink
                      className='text-[16px] px-6 font-semibold h-11'
                      href={giftPath()}
                      color='secondary'
                    >
                      Gift
                    </ButtonLink>
                  </div>
                ) : null}
                {isAdminMode ||
                user?.ActiveProfileType === ActiveProfileType.Planter ? null : (
                  <div>
                    <ButtonLink
                      size={user ? 'large' : 'base'}
                      className='text-[16px] font-semibold h-11'
                      href={donatePath()}
                    >
                      Donate
                    </ButtonLink>
                  </div>
                )}
              </div>

              {/* account switch/auth links */}
              {renderAccountLinks()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
