import Image from 'next/image'
import { FaTimes, FaBars } from 'react-icons/fa'

import { useMenuState } from 'src/providers/MenuStateProvider'
import { useUser } from 'src/providers/UserProvider'

import { IconButton } from 'src/components/atoms/IconButton'
import { InternalLink } from 'src/components/atoms/Link'

import { homePath } from 'src/config/paths'

export const MobileNavigationToggle = () => {
  const { isOpen, setIsOpen } = useMenuState()
  const { user } = useUser()

  const handleSetIsOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='mx-[20px] w-full justify-between items-center px-3 visible flex lg:hidden'>
      <InternalLink href={homePath}>
        <Image
          alt='Trees That Count logo'
          src={user ? '/img/brand-mobile.svg' : '/img/logos/ttc-logo.svg'}
          height={40}
          width={user ? 40 : 110}
        />
      </InternalLink>
      <div className='flex space-x-1 items-center'>
        <IconButton
          icon={isOpen ? FaTimes : FaBars}
          ariaLabel={isOpen ? 'Close menu' : 'Open menu'}
          onClick={handleSetIsOpen}
          size='small'
        />
      </div>
    </div>
  )
}
