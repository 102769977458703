import { NotificationType, notify } from 'src/providers/NotificationProvider'
import { useUser } from 'src/providers/UserProvider'

import { Button } from 'src/components/atoms/Button'
import { Divider } from 'src/components/atoms/Divider'
import { ExternalLink } from 'src/components/atoms/Link'
import { Heading3, Paragraph } from 'src/components/atoms/Typography'
import { HSpaceBetweenStack } from 'src/components/templates/HStack'

import { useCreateAccountProfileMutation } from 'src/config/generated/graphql'
import { ActiveProfileType } from 'src/types/account'

export interface CreateAccountProfileTypeModalProps {
  closeModal: () => void
  profileType?: string
}

export const CreateAccountProfileTypeModal = ({
  closeModal,
  profileType
}: CreateAccountProfileTypeModalProps) => {
  const [createAccountProfile, { loading }] = useCreateAccountProfileMutation()
  const { user, updateActiveAccountId } = useUser()

  const handleCreateProfile = async () => {
    if (!profileType) return

    createAccountProfile({
      variables: {
        input: {
          accountId: user?.ActiveAccountId,
          profileType
        }
      },
      onCompleted: res => {
        if (res.createAccountProfile) {
          updateActiveAccountId({
            variables: {
              ActiveAccountId: res.createAccountProfile.accountId,
              id: user?.Id,
              ActiveProfileType: res.createAccountProfile.profileType
            },
            onCompleted: () => {
              notify({
                message: `${res.createAccountProfile?.profileType} account created successfully.`,
                type: NotificationType.success
              })
              closeModal()
            }
          })
        }
      }
    })
  }

  return (
    <div className='w-full sm:w-[520px] flex flex-col gap-8'>
      <Heading3 className='text-charcoal !m-0 text-[28px] leading-[39px] font-semibold'>
        Become a {profileType} with Trees That Count
      </Heading3>
      {profileType === ActiveProfileType.Planter ? (
        <>
          <Paragraph className='text-shark !m-0'>
            We support planting projects that restore native forests,
            biodiversity, and community well-being.
          </Paragraph>
          <Paragraph className='text-shark !m-0'>
            Before creating a planter account, check if you’re eligible to
            request native trees -{' '}
            <ExternalLink href='https://help.treesthatcount.co.nz/en/articles/9560670-requirements-to-be-a-planter-with-trees-that-count'>
              see planter requirements.
            </ExternalLink>
          </Paragraph>
        </>
      ) : (
        <Paragraph className='text-shark !m-0'>
          Support planters with their restoration efforts by funding through
          Trees That Count.
        </Paragraph>
      )}
      <Divider className='text-shark !m-0' />

      <HSpaceBetweenStack>
        <Button className='mr-2' onClick={closeModal} variant='outlined'>
          Close
        </Button>
        <Button
          disabled={loading}
          className='mr-2'
          onClick={handleCreateProfile}
          type='submit'
        >
          Add a {profileType} account
        </Button>
      </HSpaceBetweenStack>
    </div>
  )
}
