import { useUser } from 'src/providers/UserProvider'

export const UserInfo = () => {
  const { user } = useUser()

  return (
    <div className='flex flex-col items-start gap-[5px]'>
      <span className='text-[16px] font-bold text-shark'>
        Kia ora {user?.FirstName || ''}
      </span>
      {user ? (
        <span className='w-full text-[14px] text-shark font-medium text-ellipsis whitespace-nowrap overflow-hidden'>
          {user?.Email}
        </span>
      ) : null}
    </div>
  )
}
