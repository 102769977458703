import { useCallback, useMemo } from 'react'
import { FaPlusCircle } from 'react-icons/fa'

import { ModalType, useModal } from 'src/providers/ModalProvider'
import { useUser } from 'src/providers/UserProvider'

import { DefaultAvatar } from 'src/components/atoms/Icons/DefaultAvatar'
import { Image } from 'src/components/atoms/Image'
import { OnClickLink } from 'src/components/atoms/Link'
import { OnClickLinkProps } from 'src/components/atoms/Link/OnClickLink'
import { MenuSectionTitle } from 'src/components/atoms/Typography'

import { env } from 'src/env/client.mjs'
import { ActiveProfileType } from 'src/types/account'

const DEFAULT_PROFILE = 'assets/img/default-profile.png'

export type AccountSwitcherProps = {
  onAccountClick?: () => void
}

export type SwitchAccountType = {
  AccountId: string
  ProfileType: ActiveProfileType
  Name: string
  Avatar: string | null | undefined
}

type AccountMenuItemProps = {
  color?: OnClickLinkProps['color']
  onClick: () => void
  profileType?: ActiveProfileType
  accountName: string
  avatar: JSX.Element | string | null | undefined
}

const AccountMenuItem = ({
  accountName,
  color = 'black',
  onClick,
  profileType,
  avatar
}: AccountMenuItemProps) => {
  const avatarComponent = useMemo(() => {
    if (avatar === DEFAULT_PROFILE) {
      return (
        <DefaultAvatar className='w-full h-full p-2 text-white bg-mostlyGreen' />
      )
    }
    if (typeof avatar === 'string') {
      return (
        <Image
          className='object-cover'
          src={`${env.NEXT_PUBLIC_ASSETS_DOMAIN}/${avatar}`}
          alt={`Avatar for ${accountName}`}
          fill
        />
      )
    }
    return avatar
  }, [accountName, avatar])

  return (
    <OnClickLink
      color={color}
      className='flex items-center group'
      onClick={onClick}
    >
      <div className='flex space-x-4 items-center py-2'>
        <div className='shrink-0'>
          <div className='flex flex-col rounded-full relative border w-10 h-10 overflow-hidden'>
            {avatarComponent}
          </div>
        </div>
        <div className='flex flex-col'>
          <span className='block !mb-0 !text-[16px] group-hover:text-inherit font-medium'>
            {accountName}
          </span>
          <span className='block text-anotherGrey2 group-hover:text-inherit text-[14px] uppercase font-medium'>
            {profileType}
          </span>
        </div>
      </div>
    </OnClickLink>
  )
}

export const AccountSwitcher = ({ onAccountClick }: AccountSwitcherProps) => {
  const { user, memberships, updateActiveAccountId, isLoading } = useUser()
  const { openModal } = useModal()

  const handleSwitchActiveAccount = useCallback(
    (id: string, profileType: ActiveProfileType) => {
      if (user?.Id) {
        updateActiveAccountId({
          variables: {
            ActiveAccountId: id,
            id: user.Id,
            ActiveProfileType: profileType
          }
        })
        if (onAccountClick) {
          onAccountClick()
        }
      }
    },
    [onAccountClick, updateActiveAccountId, user]
  )

  const createProfileTypeLink = useMemo(() => {
    if (
      !user ||
      (user.Account?.PlanterId && user.Account.FunderId) ||
      (!user.Account?.PlanterId && !user.Account?.FunderId)
    )
      return null

    return (
      <AccountMenuItem
        color='green'
        accountName={`Become a ${user.Account.FunderId ? 'planter' : 'funder'}`}
        avatar={
          <FaPlusCircle className='text-mostlyGreen group-hover:text-primary justify-self-center self-center h-full' />
        }
        onClick={() => {
          openModal(ModalType.CreateAccountProfileType, {
            accountId: user.ActiveAccountId,
            profileType:
              user.ActiveProfileType === ActiveProfileType.Funder
                ? ActiveProfileType.Planter
                : ActiveProfileType.Funder
          })
        }}
      />
    )
  }, [openModal, user])

  const filteredMemberships = useMemo(() => {
    if (!memberships || isLoading) return []
    return memberships
      ?.reduce<SwitchAccountType[]>((acc, membership) => {
        const allMemberships = [...acc]
        if (membership.Account.FunderId) {
          allMemberships.push({
            AccountId: membership.AccountId,
            Name: membership.Account.Name,
            Avatar: membership.Account.Avatar,
            ProfileType: ActiveProfileType.Funder
          })
        }
        if (membership.Account.PlanterId) {
          allMemberships.push({
            AccountId: membership.AccountId,
            Name: membership.Account.Name,
            Avatar: membership.Account.Avatar,
            ProfileType: ActiveProfileType.Planter
          })
        }
        return allMemberships
      }, [])
      .filter(membership => {
        return (
          user?.ActiveAccountId !== membership.AccountId ||
          (user?.ActiveProfileType !== membership.ProfileType &&
            user?.ActiveAccountId === membership.AccountId)
        )
      })
      .sort((a, b) => a.Name.localeCompare(b.Name))
  }, [memberships, user, isLoading])

  return (
    <>
      <MenuSectionTitle className='text-[#999999] my-[10px]'>
        Switch account
      </MenuSectionTitle>
      <div className='flex flex-col overflow-hidden overflow-y-auto'>
        {filteredMemberships.map(membership => (
          <AccountMenuItem
            key={`${membership.AccountId}_${membership.ProfileType}`}
            accountName={membership.Name}
            profileType={membership.ProfileType}
            avatar={membership.Avatar}
            onClick={() => {
              handleSwitchActiveAccount(
                membership.AccountId,
                membership.ProfileType
              )
            }}
          />
        ))}
        {createProfileTypeLink}
      </div>
    </>
  )
}
