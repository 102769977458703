export enum LinkOptionType {
  Link = 'Link',
  Seperator = 'Seperator'
}

type LinkOptionItem = {
  type: LinkOptionType
}

type Option = LinkOptionItem & {
  type: LinkOptionType.Link
  label: string
  path: string
  openInNewWindow?: boolean
  description?: string
}

type Seperator = LinkOptionItem & {
  type: LinkOptionType.Seperator
}

export type LinkOption = {
  label: string
  path?: string
  requiresUser: boolean
  options?: (Option | Seperator)[]
  subMenu?: {
    title?: string
    subMenuOptions?: (Option | Seperator)[]
  }
}
