/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { useMenuState } from 'src/providers/MenuStateProvider'
import { useUser } from 'src/providers/UserProvider'

import { DefaultAvatar } from 'src/components/atoms/Icons/DefaultAvatar'
import { Image } from 'src/components/atoms/Image'
import { MenuSectionTitle } from 'src/components/atoms/Typography'

import { env } from 'src/env/client.mjs'
import useMediaQuery from 'src/hooks/useMediaQuery'

import { AccountSwitcher } from '../AccountSwitcher'

const DEFAULT_PROFILE = 'assets/img/default-profile.png'

export const AccountInfo = () => {
  const { user } = useUser()
  const accountSwitcherRef = useRef<HTMLDivElement>(null)

  const isMobile = useMediaQuery('(max-width: 1024px)')

  const [accountsVisible, setAccountsVisible] = useState(false)
  const { setIsOpen, isAccountsVisible } = useMenuState()

  useEffect(() => {
    setAccountsVisible(isAccountsVisible)
    if (isAccountsVisible) {
      setTimeout(() => {
        if (accountSwitcherRef.current && isMobile)
          accountSwitcherRef.current.scrollIntoView({
            inline: 'end',
            behavior: 'smooth'
          })
      }, 250)
    }
  }, [isAccountsVisible, isMobile])

  return (
    <div className='flex flex-col items-start'>
      <MenuSectionTitle className='text-[#999999]'>Account</MenuSectionTitle>
      <div className='w-full'>
        <div
          className='w-full max-w-full py-[10px] flex flex-row gap-[10px] items-center group'
          tabIndex={0}
          role='button'
          onKeyDown={() => {}}
          onClick={() => {
            setAccountsVisible(!accountsVisible)
          }}
        >
          <div className='flex-shrink-0 h-[54px] w-[54px] relative overflow-hidden rounded-full'>
            {user?.Account?.Avatar &&
            user?.Account?.Avatar !== DEFAULT_PROFILE ? (
              <Image
                className='object-cover'
                src={`${env.NEXT_PUBLIC_ASSETS_DOMAIN}/${user?.Account?.Avatar}`}
                alt={`Avatar for ${user?.Account?.Name}`}
                fill
              />
            ) : (
              <DefaultAvatar className='w-full h-full p-2 text-white bg-mostlyGreen' />
            )}
          </div>
          <div className='flex-1 flex flex-col items-start justify-center overflow-hidden'>
            <span className='block text-left w-full !m-0 text-charcoal group-hover:!text-primary font-medium !text-[16px] leading-[20px]'>
              {user?.Account?.Name}
            </span>
            <span className='block text-anotherGrey2 group-hover:!text-primary uppercase text-[14px] font-medium'>
              {user?.ActiveProfileType}
            </span>
          </div>

          <div className='flex-shrink-0 group-hover:!text-primary'>
            {accountsVisible ? (
              <MdKeyboardArrowUp size='20px' />
            ) : (
              <MdKeyboardArrowDown size='20px' />
            )}
          </div>
        </div>
      </div>
      <div
        ref={accountSwitcherRef}
        className={`w-full flex flex-col transition-[max-height] duration-200 overflow-hidden ${
          accountsVisible ? 'max-h-96' : 'max-h-0'
        }`}
      >
        <AccountSwitcher
          onAccountClick={() => {
            setIsOpen(false)
            setAccountsVisible(false)
          }}
        />
      </div>
    </div>
  )
}
