import { UserProviderUserType } from 'src/providers/UserProvider'

import {
  adminListApplicationsPath,
  adminListFundingPath,
  adminListRegistriesPath,
  adminListAccountsPath,
  adminTreePoolPath,
  listRegistriesPath,
  adminReportsPath,
  dashboardPath,
  supporterToolkitPath,
  adminBulkPath,
  listProjectsPath,
  adminListProjectsPath,
  giftingPath,
  listPlantingProjectsPath,
  blogPath,
  howItWorksPath,
  leaderboardsPath,
  resourcesPath,
  aboutUsPath,
  plantingEventsPath,
  plantersTakeActionPath,
  businessTakeActionPath,
  individualsFamiliesTakeActionPath,
  planterToolkitPath,
  supportedProjectsPath,
  landscapeScalePath,
  allActivitiesPath
} from 'src/config/paths'
import { ActiveProfileType } from 'src/types/account'

import { LinkOption, LinkOptionType } from './types'

export const buildUserLinks = ({
  user
}: {
  user?: UserProviderUserType
}): LinkOption[] => {
  const dashboardOptions: LinkOption = {
    requiresUser: true,
    path: dashboardPath(),
    label: 'Dashboard'
  }

  const communityOptions: LinkOption = {
    requiresUser: true,
    label: 'Community',
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Activity feed',
        path: allActivitiesPath,
        description:
          'People are taking action every day and leaving messages of support.'
      },
      {
        type: LinkOptionType.Link,
        label: 'Leaderboard',
        path: leaderboardsPath(),
        description:
          'See the funders leading the way in making a positive nature impact.'
      },
      {
        type: LinkOptionType.Link,
        label: 'Tree registries',
        path: listRegistriesPath,
        description:
          'Browse tree campaigns created by the Trees That Count community.'
      }
    ]
  }

  const projectOptions: LinkOption = {
    requiresUser: true,
    label: 'Projects',
    options: []
  }

  if (
    user?.ActiveProfileType === ActiveProfileType.Planter &&
    user.Account?.Planter?.ProjectsCount
  ) {
    projectOptions.options?.push({
      type: LinkOptionType.Link,
      label: 'Planting projects',
      path: listProjectsPath,
      description: 'View and manage your planting projects.'
    })
  }

  if (user?.ActiveProfileType === ActiveProfileType.Funder) {
    projectOptions.options?.push({
      type: LinkOptionType.Link,
      label: 'Supported projects',
      path: supportedProjectsPath,
      description: 'See the projects your funding has supported.'
    })
  }

  projectOptions.options?.push({
    type: LinkOptionType.Link,
    label: 'Browse all projects',
    path: listPlantingProjectsPath(),
    description:
      'See all planting projects across New Zealand supported through Trees That Count.'
  })

  const toolAndResourceOptions: LinkOption = {
    requiresUser: true,
    label:
      user?.ActiveProfileType === ActiveProfileType.Funder
        ? 'Supporter toolkit'
        : 'Resources',
    options: []
  }

  if (user?.ActiveProfileType === ActiveProfileType.Planter) {
    toolAndResourceOptions.options?.push(
      {
        type: LinkOptionType.Link,
        label: 'Planter toolkit',
        path: planterToolkitPath,
        description:
          'Access resources and videos to help your planting project thrive.'
      },
      {
        type: LinkOptionType.Link,
        label: 'Help centre',
        path: 'https://help.treesthatcount.co.nz/en/',
        openInNewWindow: true,
        description:
          'Need an extra hand? View our help centre for useful guides.'
      }
    )
  }

  if (user?.ActiveProfileType === ActiveProfileType.Funder) {
    toolAndResourceOptions.options?.push(
      {
        type: LinkOptionType.Link,
        label: 'Committed to Climate Marks',
        path: `${supporterToolkitPath}?activeTab=Milestones`,
        description:
          'Download your climate marks and use in your impact storytelling.'
      },
      {
        type: LinkOptionType.Link,
        label: 'Marketing resources',
        path: `${supporterToolkitPath}?activeTab=Resources`,
        description:
          'Download marketing assets, logos, and communication guides to help share your impact.'
      }
    )
  }

  const exploreOptions: LinkOption = {
    label: 'Explore',
    requiresUser: false,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Planting projects',
        path: listPlantingProjectsPath()
      },
      {
        type: LinkOptionType.Link,
        label: 'News and stories',
        path: blogPath()
      },
      {
        type: LinkOptionType.Link,
        label: 'How it works',
        path: howItWorksPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Leaderboards',
        path: leaderboardsPath()
      },
      {
        type: LinkOptionType.Link,
        label: 'Registries',
        path: listRegistriesPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Resources',
        path: resourcesPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Events',
        path: plantingEventsPath
      },
      {
        type: LinkOptionType.Link,
        label: 'About us',
        path: aboutUsPath
      }
    ]
  }

  const takeActionOptions: LinkOption = {
    label: 'Take action',
    requiresUser: false,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Gifting',
        path: giftingPath
      },
      {
        type: LinkOptionType.Seperator
      },
      {
        type: LinkOptionType.Link,
        label: 'Individuals and families',
        path: individualsFamiliesTakeActionPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Businesses',
        path: businessTakeActionPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Landscape scale restoration',
        path: landscapeScalePath
      },
      {
        type: LinkOptionType.Link,
        label: 'Planters',
        path: plantersTakeActionPath
      }
    ]
  }

  const guestOptions = [takeActionOptions, exploreOptions]

  const userOptions = [
    dashboardOptions,
    projectOptions,
    toolAndResourceOptions,
    communityOptions
  ]

  return user ? userOptions : guestOptions
}

export const buildAdvisorLinks = (): LinkOption[] => [
  {
    label: 'Projects',
    path: adminListProjectsPath,
    requiresUser: true
  },
  {
    label: 'Applications',
    path: adminListApplicationsPath(),
    requiresUser: true
  }
]

export const buildAdminLinks = (): LinkOption[] => [
  {
    label: 'Dashboard',
    path: adminTreePoolPath,
    requiresUser: true,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Tree pool',
        path: adminTreePoolPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Reports',
        path: adminReportsPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Bulk Gifts',
        path: adminBulkPath
      }
    ]
  },
  { label: 'Funding', path: adminListFundingPath, requiresUser: true },
  {
    label: 'Projects',
    path: adminListProjectsPath,
    requiresUser: true
  },
  {
    label: 'Applications',
    path: adminListApplicationsPath(),
    requiresUser: true
  },
  {
    label: 'People',
    path: adminListAccountsPath,
    requiresUser: true,
    options: [
      {
        type: LinkOptionType.Link,
        label: 'Accounts',
        path: adminListAccountsPath
      },
      {
        type: LinkOptionType.Link,
        label: 'Registries',
        path: adminListRegistriesPath
      }
    ]
  }
]
