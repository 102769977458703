import Dropdown from 'rc-dropdown'
import { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { InternalLink } from 'src/components/atoms/Link'
import { LinkOption, LinkOptionType } from 'src/components/layouts/Header/types'

import { ArrayElement } from 'src/types/common'

export type MenuItemProps = {
  option: ArrayElement<NonNullable<LinkOption['options']>>
  onClick?: () => void
}

export const MenuItem = ({ option, onClick }: MenuItemProps) => {
  // ignore seperators
  if (option.type === LinkOptionType.Seperator) return <div />

  const isExtendedMenuItem = !!option.description

  return (
    <InternalLink
      href={option.path}
      openInNewTab={option.openInNewWindow}
      highlightActive
      onClick={onClick}
      color='black'
    >
      <div
        className={`flex flex-row pl-10 lg:px-[30px] lg:pl-5 px-5 ${
          isExtendedMenuItem ? 'lg:py-5 py-[15px]' : 'py-3'
        } gap-[10px] hover:bg-lightGrey`}
      >
        <div className='flex flex-col gap-[5px]'>
          <span
            className={`text-[16px] leading-[24px] ${
              isExtendedMenuItem ? 'font-semibold' : 'font-medium'
            }`}
          >
            {option.label}
          </span>
          {option.description ? (
            <span className='text-anotherGrey2 text-[14px] leading-[20px] font-medium'>
              {option.description}
            </span>
          ) : null}
        </div>
      </div>
    </InternalLink>
  )
}

export type SubMenuProps = {
  subMenu: NonNullable<LinkOption['subMenu']>
}

export const SubMenu = ({ subMenu }: SubMenuProps) => {
  return (
    <div className='flex flex-col lg:pl-0 pl-5 bg-aquaSpring bg-opacity-40 pt-5 pb-0 min-w-[200px]'>
      <span className='text-[14px] font-bold uppercase text-[#aaaaaa] mx-5'>
        {subMenu.title}
      </span>
      {subMenu.subMenuOptions?.map((subMenuItem, i) => {
        if (subMenuItem.type === LinkOptionType.Seperator)
          return <div key={i} />

        return (
          <InternalLink
            key={`${subMenuItem.label}_${i}`}
            color='black'
            href={subMenuItem.path as string}
            highlightActive
          >
            <div className='text-[16px] font-medium py-[14px] hover:bg-white hover:text-primary px-5'>
              <span
                className='truncate max-w-[200px]'
                title={subMenuItem.label}
              >
                {subMenuItem.label}
              </span>
            </div>
          </InternalLink>
        )
      })}
    </div>
  )
}

export type MenuDropdownProps = {
  menuItem: LinkOption
}

export const MenuDropdown = ({
  menuItem: { label, options, subMenu }
}: MenuDropdownProps) => {
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <Dropdown
      animation='slide-up'
      onVisibleChange={v => {
        setVisible(v)
      }}
      placement='bottomLeft'
      visible={visible}
      overlay={
        <div className='flex flex-row bg-white border border-superSilver shadow-[0px_4px_8px_2px_rgba(51,51,51,0.15)] rounded-[10px] overflow-hidden'>
          <div className='max-w-[395px] min-w-[240px]'>
            {options?.map((option, i) => {
              return (
                <MenuItem
                  onClick={() => {
                    setVisible(false)
                  }}
                  key={i}
                  option={option}
                />
              )
            })}
          </div>
          {subMenu && subMenu.subMenuOptions?.length ? (
            <SubMenu subMenu={subMenu} />
          ) : null}
        </div>
      }
    >
      <div className='!text-shark flex cursor-default gap-[5px] text-nowrap items-center text-[16px] font-medium p-[10px]'>
        {label}
        <span>
          {visible ? (
            <MdKeyboardArrowUp size='20px' />
          ) : (
            <MdKeyboardArrowDown size='20px' />
          )}
        </span>
      </div>
    </Dropdown>
  )
}
