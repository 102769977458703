import ReactModal from 'react-modal'

import { ModalType, useModal } from 'src/providers/ModalProvider'

import { ChangeUserRoleModal } from './account/ChangeUserRoleModal'
import { CreateAccountProfileTypeModal } from './account/CreateAccountProfileTypeModal/CreateAccountProfileTypeModal'
import { DeleteInviteModal } from './account/DeleteInviteModal'
import { InviteAccountUserModal } from './account/InviteAccountUserModal'
import { RemoveMembershipModal } from './account/RemoveMembershipModal'
import ResendInviteModal from './account/ResendInviteModal/ResendInviteModal'
import {
  ApproveApplicationModal,
  DeclineApplicationModal,
  ReturnApplicationToPendingModal,
  WithdrawApplicationModal
} from './application'
import { DeleteApplicationModal } from './application/DeleteApplicationModal'
import { DeleteProjectModal } from './application/DeleteProjectModal'
import { SubmitApplicationModal } from './application/SubmitApplicationModal'
import { UpdateApplicationCountsModal } from './application/UpdateApplicationCountsModal'
import { UpdateApplicationSeasonModal } from './application/UpdateApplicationSeasonModal'
import { CancelModal } from './cancel/CancelModal'
import { SaveAsDraftModal } from './cancel/SaveAsDraftModal'
import { RemovePaymentMethodModal } from './payments/RemovePaymentMethodModal'
import { SubmitPlantingModal } from './planting'
import { DeletePlantingModal } from './planting/DeletePlantingModal/DeletePlantingModal'
import { UpdateProjectVisibilityModal } from './project'
import { FeatureProjectModal } from './project/FeatureProjectModal'
import { UpdatePurchaseRecipientEmail } from './purchase/UpdatePurchaseRecipientEmail/UpdatePurchaseRecipientEmail'
import { CancelSubscriptionModal } from './subscription'

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#__next')

export const Modal = () => {
  const { closeModal, isModalOpen, modalType, modalProps } = useModal()

  function renderModalContents() {
    switch (modalType) {
      case ModalType.DeleteProject:
        return <DeleteProjectModal closeModal={closeModal} {...modalProps} />
      case ModalType.DeleteInvite:
        return <DeleteInviteModal closeModal={closeModal} {...modalProps} />
      case ModalType.ResendInvite:
        return <ResendInviteModal closeModal={closeModal} {...modalProps} />
      case ModalType.RemoveMembership:
        return <RemoveMembershipModal closeModal={closeModal} {...modalProps} />
      case ModalType.UpdateMembershipRole:
        return <ChangeUserRoleModal closeModal={closeModal} {...modalProps} />
      case ModalType.InviteAccountUser:
        return (
          <InviteAccountUserModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.CancelSubscription:
        return (
          <CancelSubscriptionModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.RemovePaymentMethod:
        return (
          <RemovePaymentMethodModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.SubmitPlanting:
        return <SubmitPlantingModal closeModal={closeModal} {...modalProps} />
      case ModalType.DeletePlanting:
        return <DeletePlantingModal closeModal={closeModal} {...modalProps} />
      case ModalType.UpdatePurchaseRecipientEmail:
        return (
          <UpdatePurchaseRecipientEmail
            closeModal={closeModal}
            {...modalProps}
          />
        )
      case ModalType.UpdateApplicationSeason:
        return (
          <UpdateApplicationSeasonModal
            closeModal={closeModal}
            {...modalProps}
          />
        )
      case ModalType.ApproveApplication:
        return (
          <ApproveApplicationModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.DeclineApplication:
        return (
          <DeclineApplicationModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.ReturnApplicationToPending:
        return (
          <ReturnApplicationToPendingModal
            closeModal={closeModal}
            {...modalProps}
          />
        )
      case ModalType.WithdrawApplication:
        return (
          <WithdrawApplicationModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.SubmitApplication:
        return (
          <SubmitApplicationModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.Cancel:
        return <CancelModal closeModal={closeModal} {...modalProps} />
      case ModalType.UpdateApplicationCounts:
        return (
          <UpdateApplicationCountsModal
            closeModal={closeModal}
            {...modalProps}
          />
        )
      case ModalType.DeleteApplication:
        return (
          <DeleteApplicationModal closeModal={closeModal} {...modalProps} />
        )
      case ModalType.UpdateProjectVisibility:
        return (
          <UpdateProjectVisibilityModal
            closeModal={closeModal}
            {...modalProps}
          />
        )
      case ModalType.FeatureProject:
        return <FeatureProjectModal closeModal={closeModal} {...modalProps} />
      case ModalType.SaveAsDraft:
        return (
          <SaveAsDraftModal
            prompt={modalProps.prompt as string}
            closeModal={closeModal}
            {...modalProps}
          />
        )
      case ModalType.CreateAccountProfileType:
        return (
          <CreateAccountProfileTypeModal
            closeModal={closeModal}
            {...modalProps}
          />
        )
      default:
        return null
    }
  }

  return (
    <ReactModal
      shouldCloseOnEsc
      className='modal-content'
      overlayClassName='modal-overlay'
      isOpen={isModalOpen}
      onRequestClose={closeModal}
    >
      {renderModalContents()}
    </ReactModal>
  )
}
