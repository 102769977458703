import { Fragment, useCallback, useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

import { useAuth } from 'src/providers/AuthProvider'
import { useMenuState } from 'src/providers/MenuStateProvider'
import { useUser } from 'src/providers/UserProvider'

import { Divider } from 'src/components/atoms/Divider'
import { InternalLink, OnClickLink } from 'src/components/atoms/Link'
import { Switch } from 'src/components/atoms/Switch'
import { MenuSectionTitle } from 'src/components/atoms/Typography'
import { AccountInfo } from 'src/components/molecules/AccountInfo'
import { MenuItem, SubMenu } from 'src/components/molecules/MenuDropdown'
import { UserInfo } from 'src/components/molecules/UserInfo'

import { useListRegistriesQuery } from 'src/config/generated/graphql'
import {
  bulkGiftPath,
  donatePath,
  giftPath,
  listFundingPath,
  listMyRegistriesPath,
  listRegistriesPath,
  taxCertificatesPath,
  viewAccountDetailPath,
  viewAccountUsersPath,
  viewProfilePath
} from 'src/config/paths'
import { ActiveProfileType } from 'src/types/account'

import { LinkOption, LinkOptionType } from '../../types'

const userActions: {
  label: string
  path: string
}[] = [
  {
    label: 'Donate',
    path: donatePath()
  },
  {
    label: 'Gift',
    path: giftPath()
  },
  {
    label: 'Bulk gift',
    path: bulkGiftPath()
  },
  {
    label: 'Tree registries',
    path: listRegistriesPath
  }
]

export type MobileAccountMenuProps = {
  links: LinkOption[]
}

export const MobileAccountMenu = ({ links }: MobileAccountMenuProps) => {
  const { isStaff, isAdvisor, logout, login, signup } = useAuth()
  const { isAdminMode, setAdminMode, user } = useUser()
  const { data: registriesData } = useListRegistriesQuery({
    variables: {
      funderId: user?.Account?.FunderId,
      offset: 0,
      limit: 1
    }
  })

  const [expandedMenuItem, setExpandedMenuItem] = useState('')
  const handleChangeAdminToggle = () => {
    setAdminMode(!isAdminMode)
  }
  const { setIsOpen } = useMenuState()

  const renderLink = useCallback(
    (link: LinkOption, index: number) => {
      if (link.options?.length) {
        return (
          <Fragment key={`link_${index}`}>
            <OnClickLink
              color='black'
              onClick={() => {
                setExpandedMenuItem(prev => {
                  if (prev === link.label) return ''
                  return link.label
                })
              }}
            >
              <div className='flex justify-between cursor-pointer text-nowrap items-center text-[16px] font-medium px-5 py-[13px] hover:bg-lightGrey'>
                {link.label}
                <span>
                  {expandedMenuItem === link.label ? (
                    <MdKeyboardArrowUp size='20px' />
                  ) : (
                    <MdKeyboardArrowDown size='20px' />
                  )}
                </span>
              </div>
            </OnClickLink>
            {expandedMenuItem === link.label ? (
              <div>
                {link.options?.map((option, i) => {
                  if (option.type === LinkOptionType.Seperator)
                    return <div key={i} />
                  return (
                    <MenuItem
                      key={i}
                      option={option}
                      onClick={() => {
                        setIsOpen(false)
                      }}
                    />
                  )
                })}
                {link.subMenu && link.subMenu.subMenuOptions?.length ? (
                  <SubMenu subMenu={link.subMenu} />
                ) : null}
              </div>
            ) : null}
          </Fragment>
        )
      }

      if (!link.path) return null
      return (
        <div
          key={`link_${index}`}
          className='text-nowrap text-[16px] font-medium px-5 py-[13px] hover:bg-lightGrey '
        >
          <InternalLink
            className='block'
            href={link.path!}
            color='black'
            highlightActive
            onClick={() => setExpandedMenuItem('')}
          >
            {link.label}
          </InternalLink>
        </div>
      )
    },
    [expandedMenuItem, setIsOpen]
  )

  return (
    <div className='z-0 bg-white shadow-[0px_4px_8px_2px_rgba(51,51,51,0.15)]'>
      {/* admin mode switch */}
      <div className='bg-anotherGrey4'>
        {isStaff || isAdvisor ? (
          <>
            <div className='space-x-4 p-5 text-shar text-[16px] font-medium'>
              <span>{isAdminMode ? 'Admin' : 'App'}</span>
              <Switch
                title='Admin'
                checked={isAdminMode}
                onChange={handleChangeAdminToggle}
              />
            </div>
            <Divider className='!m-0' />
          </>
        ) : null}
        <div className='bg-anotherGrey4 p-5'>
          <UserInfo />
        </div>
      </div>

      <div id='MENULINKS'>{links.map(renderLink)}</div>
      <Divider className='!m-0' />
      {isAdminMode ||
      user?.ActiveProfileType === ActiveProfileType.Planter ? null : (
        <>
          <div className='flex flex-col pt-5 pb-[10px]'>
            <MenuSectionTitle className='text-osloGrey pb-[10px] px-5'>
              Fund native trees
            </MenuSectionTitle>
            {userActions.map(action => {
              return (
                <InternalLink
                  key={action.label}
                  className='hover:bg-lightGrey px-5 py-[13px]'
                  color='black'
                  href={action.path}
                  highlightActive
                >
                  <div className='flex flex-row gap-[10px]'>
                    <span>{action.label}</span>
                  </div>
                </InternalLink>
              )
            })}
          </div>
          <Divider className='!m-0' />
        </>
      )}
      {user?.Account ? (
        <div className='p-5'>
          <AccountInfo />
        </div>
      ) : null}
      <Divider className='!m-0' />
      {user ? (
        <>
          {user.Account &&
          user.ActiveProfileType === ActiveProfileType.Funder ? (
            <>
              <InternalLink
                className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
                color='black'
                highlightActive
                href={listFundingPath}
              >
                Transactions
              </InternalLink>
              <InternalLink
                className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
                color='black'
                highlightActive
                href={taxCertificatesPath}
              >
                Tax certificates
              </InternalLink>
              {registriesData?.registries.length ? (
                <InternalLink
                  className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
                  color='black'
                  highlightActive
                  href={listMyRegistriesPath}
                >
                  Your tree registries
                </InternalLink>
              ) : null}
            </>
          ) : null}
          {user.Account ? (
            <InternalLink
              className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
              color='black'
              highlightActive
              href={viewAccountUsersPath}
            >
              Manage users
            </InternalLink>
          ) : null}
          <InternalLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            highlightActive
            href={viewAccountDetailPath}
          >
            Account settings
          </InternalLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={logout}
          >
            Log out
          </OnClickLink>
        </>
      ) : (
        <>
          <OnClickLink
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            color='black'
            onClick={signup}
          >
            Sign up
          </OnClickLink>
          <Divider className='!m-0' />
          <OnClickLink
            color='black'
            className='hover:bg-lightGrey py-[13px] px-5 flex flex-row justify-between items-center'
            onClick={login}
          >
            Log in
          </OnClickLink>
        </>
      )}
    </div>
  )
}
